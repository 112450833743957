import * as analytics from "@/analytics";
import { profile as profileApi } from "@/api/entities";
import { createMessage } from "@/api/api.messages";
import { useFavorites } from "@components/Favorites/FavoritesContext";
import { useUserSession } from "@/contexts/UserSession";
import { mediaContentLoadingAnimation } from "@/design-system/animations/MediaContentLoadingAnimation";
import { QUERIES } from "@/design-system/breakpoints";
import { Button } from "@/design-system/components/Button";
import { useSupportedLocale } from "@/hooks/useLocale";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useTranslations } from "next-intl";
import { useSearchParams } from "next/navigation";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ProfileContactAuth } from "./ProfileContactAuth";
import { ProfileContactHeader } from "./ProfileContactHeader";
import { ProfileContactMessage } from "./ProfileContactMessage";
import {
  convertUTCTimeslotsToAvailabilityByDay,
  UpcomingAvailabilityByDay,
} from "@/utils/TimeUtils";
import { ICTheme } from "@/design-system/ICTheme";
import { pushDataLayerEventAsync } from "@/analytics/analytics.datalayer";
import { navigateToDashboardAfterInquiry } from "@/helpers/AngularDashboardNavigator";

interface ProfileContactDialogProps {
  profile: profileApi.Profile;
  upcomingAvailabilitySlots: string[];
  isOpen: boolean;
  selectedDatetime?: string;
  setOpen: (isOpen: boolean) => void;
  setSelectedDatetime: (datetime?: string) => void;
}

function ProfileContactDialog({
  profile,
  upcomingAvailabilitySlots,
  isOpen,
  selectedDatetime,
  setOpen,
  setSelectedDatetime,
}: ProfileContactDialogProps) {
  const locale = useSupportedLocale();
  const t = useTranslations("Profile.contact");
  const { session, isLoadingSession } = useUserSession();
  const [state, setState] = React.useState<
    "loading" | "authenticate" | "message"
  >("message");
  const [isSending, setIsSending] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const { isAFavorite } = useFavorites();

  const upcomingAvailability = useMemo(
    () => convertUTCTimeslotsToAvailabilityByDay(upcomingAvailabilitySlots),
    [upcomingAvailabilitySlots],
  );

  const handleSend = async (
    message: string,
    appointmentUTCTimestamp: number | undefined,
  ) => {
    analytics.directory.therapistInquirySubmitted({
      source_page: "therapist_profile_page",
      source_flow: "contact",
      appointment_selected: !!appointmentUTCTimestamp,
      appointments_available:
        profile.calendar_enabled &&
        profile.is_available &&
        upcomingAvailability.length > 0,
      therapist_user_id: profile.user.id.toString(),
      has_active_video: profile.has_active_video,
      is_a_favorite: isAFavorite(profile.user.id.toString()),
    });

    if (!session.user) {
      setState("authenticate");
      return;
    }

    setIsError(false);
    setIsSending(true);
    const res = await createMessage(
      profile.user.id,
      message,
      appointmentUTCTimestamp
        ? {
            timestamp: appointmentUTCTimestamp,
          }
        : undefined,
    );

    if (res.status === "ok") {
      if (res.value.isNewConversation) {
        await pushDataLayerEventAsync("first_message_sent");
      }

      analytics.directory.messageSent({
        therapist_user_id: profile.user.id.toString(),
        is_first_message: res.value.isNewConversation,
        conversation_id: res.value.conversationId,
        has_active_video: profile.has_active_video,
        is_a_favorite: isAFavorite(profile.user.id.toString()),
        therapist_display_city_slug: profile.city.slug,
      });
      await navigateToDashboardAfterInquiry(locale);
    } else {
      setIsSending(false);
      setIsError(true);
    }
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      // When the dialog is closed, reset the state
      if (isLoadingSession) {
        setState("loading");
      } else {
        setState("message");
      }
      setSelectedDatetime(undefined);
    }
  };

  React.useEffect(() => {
    // set state to message when we have a valid session user
    if (isLoadingSession) {
      setState("loading");
    } else {
      setState("message");
    }
  }, [session.user, isLoadingSession]);

  const searchParams = useSearchParams();
  React.useEffect(() => {
    // Only open when the component is mounting client-side to work
    // around an hydration issue with Radix.
    // https://github.com/radix-ui/primitives/issues/1386
    const showContact = searchParams?.get("contact") === "1";
    if (!isOpen && showContact) {
      setOpen(true);
      analytics.directory.therapistInquiryInitiated(
        {
          therapist_user_id: profile.user.id.toString(),
          source_page: "therapist_profile_page",
          source_flow: "contact",
          source_action: "deeplink",
          has_active_video: profile.has_active_video,
          is_a_favorite: isAFavorite(profile.user.id.toString()),
        },
        searchParams,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (selectedDatetime) {
      if (!message && selectedDatetime) {
        setMessage(
          t("default_message_for_appointment", { name: profile.firstname }),
        );
      }
    } else {
      setMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when selectedDateTime changes
  }, [selectedDatetime]);

  // If we know this user is in the EAP program and the therapist is now, show the warning that they may have to pay
  const showEAPWarning: boolean = !!(
    session &&
    session.user &&
    !session.user.cannot_book_eap_session_reason &&
    !profile.active_in_eap_program
  );

  return (
    <div>
      <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
        <Dialog.Portal>
          <ICTheme>
            <DialogOverlay />
            <DialogContent>
              <HeaderWrapper>
                <ProfileContactHeader
                  picture_url={profile.profile_picture_url}
                  full_name={profile.full_name}
                  jobtitle={profile.jobtitle}
                />
                <ContactControls>
                  <Dialog.Close asChild>
                    <DialogCloseButton aria-label="Close">
                      <DialogCloseIcon width={20} height={20} />
                    </DialogCloseButton>
                  </Dialog.Close>
                </ContactControls>
              </HeaderWrapper>
              {isError && <ErrorMessage>{t("generic_error")}</ErrorMessage>}
              <ContentWrapper>
                {state === "loading" && <Loading />}
                {state === "authenticate" && <Authenticate profile={profile} />}
                {state === "message" && (
                  <Message
                    profile={profile}
                    upcomingAvailability={upcomingAvailability}
                    isBusy={isSending}
                    message={message}
                    setMessage={setMessage}
                    selectedDatetime={selectedDatetime}
                    setSelectedDatetime={setSelectedDatetime}
                    showEAPWarning={showEAPWarning}
                    onSend={handleSend}
                  />
                )}
              </ContentWrapper>
            </DialogContent>
          </ICTheme>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}

function Loading() {
  return (
    <VerticalPaddingWrapper>
      <Placeholder />
    </VerticalPaddingWrapper>
  );
}

function Authenticate({ profile }: { profile: profileApi.Profile }) {
  return (
    <VerticalPaddingWrapper>
      <ProfileContactAuth
        user_id={profile.user.id.toString()}
        name={profile.firstname}
      />
    </VerticalPaddingWrapper>
  );
}

function Message({
  profile,
  upcomingAvailability,
  isBusy,
  message,
  setMessage,
  selectedDatetime,
  setSelectedDatetime,
  showEAPWarning,
  onSend,
}: {
  profile: profileApi.Profile;
  upcomingAvailability: UpcomingAvailabilityByDay;
  isBusy: boolean;
  message: string;
  setMessage: (message: string) => void;
  selectedDatetime: string | undefined;
  setSelectedDatetime: (datetime: string) => void;
  showEAPWarning: boolean;
  onSend: (
    message: string,
    appointmentUTCTimestamp: number | undefined,
  ) => void;
}) {
  return (
    <ProfileContactMessage
      profile={profile}
      upcomingAvailabilitySlots={upcomingAvailability}
      isBusy={isBusy}
      message={message}
      setMessage={setMessage}
      selectedDatetime={selectedDatetime}
      showEAPWarning={showEAPWarning}
      setSelectedDatetime={setSelectedDatetime}
      onSend={onSend}
    />
  );
}

const DialogOverlay = styled(Dialog.Overlay)`
  background-color: var(--blackA9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media ${QUERIES.mobile} {
    display: none;
  }
`;

const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: fit-content;
  width: 700px;
  max-height: 700px;

  background-color: white;
  border-radius: 6px;
  border: 2px var(--gray6) solid;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow: hidden;

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @media ${QUERIES.mobile} {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  background-color: var(--green-low);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;

  @media ${QUERIES.mobile} {
    width: 100%;
    height: 100%;
  }
`;

const VerticalPaddingWrapper = styled.div`
  padding: var(--spacer-size-4) 0;
`;

const ContactControls = styled.div`
  display: flex;
  justify-content: end;
`;

const DialogCloseButton = styled(Button).attrs({
  tertiary: true,
  tiny: true,
})`
  height: 40px;
  width: 40px;
`;

const DialogCloseIcon = styled(Cross2Icon)`
  float: right;
  color: var(--black);
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  padding: 12px;
  text-align: center;
  background-color: var(--red-low);
`;

export const Placeholder = styled.div`
  background-color: var(--grey-low);
  width: 100%;
  height: 200px;
  margin: 0 24px; // to match the padding of the message area
  ${mediaContentLoadingAnimation}
`;

export { ProfileContactDialog };
