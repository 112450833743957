import * as analytics from "@/analytics";
import { useUserSession } from "@/contexts/UserSession";
import { QUERIES } from "@/design-system/breakpoints";
import { Button } from "@/design-system/components/Button";
import { useTranslations } from "next-intl";
import { useEffect } from "react";
import styled from "styled-components";

type ProfileContactHeaderProps = {
  user_id: string;
  name: string;
};

export function ProfileContactAuth({
  user_id,
  name,
}: ProfileContactHeaderProps) {
  const t = useTranslations("Profile.contact");
  const { openLoginPopup, openSignupPopup } = useUserSession();
  const returnTo = new URL(window.location.href);
  returnTo.searchParams.set("contact", "1");

  useEffect(() => {
    analytics.auth.trackAuthPromptViewed({
      therapist_user_id: user_id,
      source_page: "therapist_profile_page",
      source_flow: "contact",
    });
  }, [user_id]);

  return (
    <ContactContainer>
      <AuthHeader>{t("auth_subtitle", { name })}</AuthHeader>
      <AuthActions>
        <SignupButton
          secondary
          onClick={(e) => {
            e.preventDefault();
            // do not await, the event will be sent when the user cones back from auth0
            analytics.auth.trackAuthStarted({
              has_contact_intent: true,
              auth_kind: "signup",
            });
            openSignupPopup();
          }}
        >
          {t("signup")}
        </SignupButton>
        <LoginButton
          primary
          onClick={(e) => {
            e.preventDefault();
            // do not await, the event will be sent when the user cones back from auth0
            analytics.auth.trackAuthStarted({
              has_contact_intent: true,
              auth_kind: "login",
            });
            openLoginPopup();
          }}
        >
          {t("login")}
        </LoginButton>
      </AuthActions>
    </ContactContainer>
  );
}

const ContactContainer = styled.div`
  flex: 1 0 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 var(--spacer-size-4);

  @media ${QUERIES.mobile} {
    align-items: center;
  }
`;

const AuthHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-4);
`;

const AuthActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-3);
  align-items: start;
  margin-top: 48px;
`;

const LoginButton = styled(Button)`
  min-width: 300px;
  text-align: center;
  text-decoration: none;
`;

const SignupButton = styled(Button)`
  padding: 12px 24px;
  color: var(--black);
  min-width: 300px;
  text-align: center;
  text-decoration: none;
`;
