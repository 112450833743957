import { useTranslations } from "next-intl";
import styled from "styled-components";

type ProfileResponseTimeMessageProps = {
  responseTime: number;
};

function ProfileResponseTimeMessage(props: ProfileResponseTimeMessageProps) {
  const responseTimeKey = getResponseTimeKey(props.responseTime);
  const t = useTranslations("Profile");
  return <ResponseTimeSpan>{t(responseTimeKey)}</ResponseTimeSpan>;
}

export default ProfileResponseTimeMessage;

function getResponseTimeKey(responseTime: number) {
  if (responseTime < 3600) {
    return "response_time.within_one_hour";
  } else if (responseTime < 86400) {
    return "response_time.within_one_day";
  } else if (responseTime < 259200) {
    return "response_time.within_three_days";
  } else if (responseTime < 604800) {
    return "response_time.within_one_week";
  } else {
    return "response_time.within_more_than_one_week";
  }
}

const ResponseTimeSpan = styled.span`
  color: var(--grey-dark-1);
  font-size: var(--font-size-14);
  width: 100%;
  text-align: center;
  display: block;
`;
