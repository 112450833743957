import React from "react";
import styled from "styled-components";

interface ArrowButtonProps {
  onClick: () => void;
}

export const RightArrowButton: React.FC<ArrowButtonProps> = ({ onClick }) => {
  return (
    <ArrowButton onClick={onClick}>
      <ArrowSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
        <path d="M530-481 332-679l43-43 241 241-241 241-43-43 198-198Z" />
      </ArrowSVG>
    </ArrowButton>
  );
};

export const LeftArrowButton: React.FC<ArrowButtonProps> = ({ onClick }) => {
  return (
    <ArrowButton onClick={onClick}>
      <ArrowSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
        <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
      </ArrowSVG>
    </ArrowButton>
  );
};

export const ArrowSVG = styled.svg`
  width: 100%;
  height: 100%;
`;

export const ArrowButton = styled.button`
  padding: 4px;
  align-items: center;
  justify-content: center;
  border: solid var(--white);
  color: var(--green-dark);
  background-color: var(--green-low);
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: var(--green-low-over);
  }

  &:disabled {
    background-color: var(--grey-light);
    color: var(--grey-dark-1);
    cursor: not-allowed;
  }
`;
