import React from "react";
import {
  SVGIconProps,
  withCommonSVGIconProps,
} from "@/design-system/icons/withCommonSVGIconProps";

const MySVGComponent: React.FC<SVGIconProps> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 1.261 9.174 L 1.261 4.717 C 1.261 4.146 1.459 3.663 1.854 3.267 C 2.25 2.872 2.733 2.674 3.304 2.674 L 20.696 2.674 C 21.267 2.674 21.75 2.872 22.146 3.267 C 22.541 3.663 22.739 4.146 22.739 4.717 L 22.739 9.174 L 21.044 9.174 L 21.044 4.717 C 21.044 4.63 21.007 4.551 20.935 4.478 C 20.862 4.406 20.783 4.37 20.696 4.37 L 3.304 4.37 C 3.217 4.37 3.138 4.406 3.065 4.478 C 2.993 4.551 2.956 4.63 2.956 4.717 L 2.956 9.174 L 1.261 9.174 Z M 3.304 18.5 C 2.733 18.5 2.25 18.302 1.854 17.907 C 1.459 17.511 1.261 17.028 1.261 16.457 L 1.261 10.87 L 2.956 10.87 L 2.956 16.457 C 2.956 16.544 2.993 16.623 3.065 16.696 C 3.138 16.768 3.217 16.804 3.304 16.804 L 20.696 16.804 C 20.783 16.804 20.862 16.768 20.935 16.696 C 21.007 16.623 21.044 16.544 21.044 16.457 L 21.044 10.87 L 22.739 10.87 L 22.739 16.457 C 22.739 17.028 22.541 17.511 22.146 17.907 C 21.75 18.302 21.267 18.5 20.696 18.5 L 3.304 18.5 Z M 0 21.326 L 0 19.63 L 24 19.63 L 24 21.326 L 0 21.326 Z M 12 10.587 Z M 1.261 10.87 L 1.261 9.174 L 7.478 9.174 C 7.635 9.174 7.784 9.216 7.925 9.3 C 8.066 9.384 8.173 9.499 8.246 9.644 L 9.824 12.783 L 13.528 6.217 C 13.601 6.084 13.702 5.982 13.832 5.911 C 13.961 5.84 14.104 5.804 14.261 5.804 C 14.417 5.804 14.566 5.84 14.708 5.911 C 14.849 5.982 14.956 6.096 15.028 6.252 L 16.489 9.174 L 22.739 9.174 L 22.739 10.87 L 16.065 10.87 C 15.88 10.87 15.702 10.821 15.532 10.725 C 15.361 10.629 15.233 10.488 15.146 10.302 L 14.204 8.413 L 10.489 14.957 C 10.417 15.102 10.31 15.21 10.168 15.283 C 10.027 15.355 9.878 15.391 9.722 15.391 C 9.565 15.391 9.419 15.349 9.284 15.265 C 9.148 15.181 9.044 15.067 8.972 14.922 L 6.946 10.87 L 1.261 10.87 Z"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
    />
  </svg>
);

MySVGComponent.displayName = "MySVGComponent";

export default withCommonSVGIconProps(MySVGComponent);
