import React from "react";
import Image from "next/image";
import { Flex, Skeleton } from "@radix-ui/themes";
import { PersonIcon } from "@radix-ui/react-icons";

interface ProfileImageProps {
  src?: string;
  alt: string;
  size?: number | string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  photoType?: "person" | "gallery";
}

function ProfileImage({
  src,
  alt,
  size = "100%",
  className,
  style,
  onClick,
  photoType = "person",
}: ProfileImageProps) {
  const [isLoading, setLoading] = React.useState(!!src);
  const [hasError, setError] = React.useState(false);

  return (
    <Skeleton loading={isLoading}>
      {!src || hasError ? (
        <FallbackIconHolder size={size}>
          {photoType === "person" ? (
            <PersonIcon
              width={"50%"}
              height={"50%"}
              color="var( --colorV2-grey-medium)"
            />
          ) : (
            <PersonIcon
              width={"50%"}
              height={"50%"}
              color="var( --colorV2-grey-medium)"
            />
          )}
        </FallbackIconHolder>
      ) : (
        <Image
          src={src}
          width={0}
          height={0}
          sizes="100vw"
          unoptimized
          style={{
            width: size,
            height: size,
            borderRadius: "12px",
            objectFit: "cover",
            ...style,
          }}
          alt={alt}
          onLoad={() => setLoading(false)}
          onError={() => {
            setLoading(false);
            setError(true);
          }}
          className={className}
          onClick={onClick}
        />
      )}
    </Skeleton>
  );
}

function FallbackIconHolder({
  size,
  children,
}: {
  size: number | string;
  children: React.ReactNode;
}) {
  return (
    <Flex
      style={{
        width: size,
        height: size,
        borderRadius: "12px",
        borderColor: "var(--colorV2-grey-medium)",
        borderWidth: "2px",
      }}
      p={"5"}
      align={"center"}
      justify={"center"}
    >
      {children}
    </Flex>
  );
}

export default ProfileImage;
