import { useTranslations } from "next-intl";
import styled from "styled-components";
import { Subtitle } from "./ProfileTypography";

type ProfileQualificationsProps = {
  education: string | null;
  experience: string | null;
  languages: string[];
  insuranceSlugs: string[];
};

function ProfileQualifications({
  education,
  experience,
  languages,
  insuranceSlugs,
}: ProfileQualificationsProps) {
  const t = useTranslations("Profile");
  const tInsurances = useTranslations("Profile.insurance_types");
  type InsurancesT = Messages["Profile"]["insurance_types"];

  const insurances = insuranceSlugs
    .map((slug) => {
      return tInsurances(`${slug}` as keyof InsurancesT);
    })
    .filter((insurance) => insurance !== "");

  return (
    <QualificationsSection>
      <Subtitle>{t("qualifications_and_experience")}</Subtitle>
      {education && education.length > 0 && (
        <div>
          <QualificationsSectionHeader>
            {t("education")}
            <QualificationsRule />
          </QualificationsSectionHeader>
          <QualificationsSectionBody>{education}</QualificationsSectionBody>
        </div>
      )}

      {!!experience && experience.length > 0 && (
        <div>
          <QualificationsSectionHeader>
            {t("experience")}
            <QualificationsRule />
          </QualificationsSectionHeader>
          <QualificationsSectionBody>{experience}</QualificationsSectionBody>
        </div>
      )}

      {languages.length > 0 && (
        <div>
          <QualificationsSectionHeader>
            {t("languages")}
            <QualificationsRule />
          </QualificationsSectionHeader>
          {languages.map((item, index) => (
            <QualificationsSectionBody key={index}>
              {item}
            </QualificationsSectionBody>
          ))}
        </div>
      )}

      {insurances.length > 0 && (
        <div>
          <QualificationsSectionHeader>
            {t("insurances")}
            <QualificationsRule />
          </QualificationsSectionHeader>
          {insurances.map((item, index) => (
            <QualificationsSectionBody key={index}>
              {item}
            </QualificationsSectionBody>
          ))}
        </div>
      )}
    </QualificationsSection>
  );
}

export default ProfileQualifications;

const QualificationsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-3);
`;

const QualificationsRule = styled.hr`
  flex-grow: 1;
  border-top: 1px solid var(--grey-medium);
  border-bottom: none;
  border-left: none;
  border-right: none;
`;

const QualificationsSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacer-size-2);
  align-items: center;
  margin-top: var(--spacer-size-2);
  margin-bottom: var(--spacer-size-2);
  font-weight: 400;
  font-size: var(--font-size-18);
  color: var(--green-dark);
`;

const QualificationsSectionBody = styled.div`
  font-weight: 400;
  font-size: var(--font-size-16);
  color: var(--black);
  // we need to use pre-line to preserve line breaks in peoples profile content
  white-space: pre-line;
`;
