import styled from "styled-components";

const RedTag = styled.div`
  display: inline-block;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: var(--red-low);
  font-size: var(--font-size-14);
  font-weight: 400;
  color: var(--red-dark);
`;

export default RedTag;
