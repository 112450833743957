import { sanitize } from "isomorphic-dompurify";
import { Body } from "./ProfileTypography";
import { TextUtils } from "@/utils/TextUtils";

type ProfileRichBodyProps = {
  render_html: boolean;
  text: string;
};

function ProfileRichBody({ render_html, text }: ProfileRichBodyProps) {
  if (render_html) {
    return (
      <Body
        dangerouslySetInnerHTML={{
          __html: sanitize(TextUtils.simpleFormat(text)),
        }}
      />
    );
  }

  return <Body>{text}</Body>;
}

export default ProfileRichBody;
