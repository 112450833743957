import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
} from "react";
import * as analytics from "@/analytics";
import { SourceAction } from "@/analytics/sourceAction";
import { Profile } from "@/api/entities/profile";
import { useFavorites } from "@components/Favorites/FavoritesContext";
import { ReadonlyURLSearchParams } from "next/navigation";

interface TherapistInquiryContextType {
  handleAppointmentRequest: (dt: string, sourceAction: SourceAction) => void;
  handleSendMessage: (sourceAction: SourceAction) => void;
  setContactDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAppointmentDatetime: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

const TherapistInquiryContext = createContext<TherapistInquiryContextType>({
  handleAppointmentRequest: async () => {},
  handleSendMessage: async () => {},
  setContactDialogVisible: () => {},
  setSelectedAppointmentDatetime: () => {},
});

interface TherapistInquiryProviderProps {
  children: ReactNode;
  profile: Profile;
  searchParams?: ReadonlyURLSearchParams; // Optional if searchParams can be undefined
  setContactDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAppointmentDatetime: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const useTherapistInquiry = () => useContext(TherapistInquiryContext);

export const TherapistInquiryProvider: React.FC<
  TherapistInquiryProviderProps
> = ({
  children,
  profile,
  searchParams,
  setContactDialogVisible,
  setSelectedAppointmentDatetime,
}) => {
  const { isAFavorite } = useFavorites();
  const handleAppointmentRequest = useCallback(
    async (dt: string, sourceAction: SourceAction) => {
      analytics.directory.therapistInquiryInitiated(
        {
          therapist_user_id: profile.user.id.toString(),
          source_flow: "contact",
          source_page: "therapist_profile_page",
          source_action: sourceAction,
          has_active_video: profile.has_active_video,
          is_a_favorite: isAFavorite(profile.user.id.toString()),
        },
        searchParams,
      );
      setSelectedAppointmentDatetime(dt);
      setContactDialogVisible(true);
    },
    [
      profile,
      searchParams,
      setSelectedAppointmentDatetime,
      setContactDialogVisible,
      isAFavorite,
    ],
  );

  const handleSendMessage = useCallback(
    async (sourceAction: SourceAction) => {
      analytics.directory.therapistInquiryInitiated(
        {
          therapist_user_id: profile.user.id.toString(),
          source_flow: "contact",
          source_page: "therapist_profile_page",
          source_action: sourceAction,
          has_active_video: profile.has_active_video,
          is_a_favorite: isAFavorite(profile.user.id.toString()),
        },
        searchParams,
      );
      setSelectedAppointmentDatetime(undefined);
      setContactDialogVisible(true);
    },
    [
      profile,
      searchParams,
      setSelectedAppointmentDatetime,
      setContactDialogVisible,
      isAFavorite,
    ],
  );

  return (
    <TherapistInquiryContext.Provider
      value={{
        handleAppointmentRequest,
        handleSendMessage,
        setContactDialogVisible,
        setSelectedAppointmentDatetime,
      }}
    >
      {children}
    </TherapistInquiryContext.Provider>
  );
};
