import { profile as api } from "@/api/entities";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProfileImage from "@components/Profile/Picture/ProfileImage";

type ProfileCarouselProps = {
  profile: api.Profile;
  onChange?: (index: number) => void;
  selectedItem?: number;
};

function ProfileCarousel({
  profile,
  onChange,
  selectedItem,
}: ProfileCarouselProps) {
  const numberOfPictures = profile.pictures.original.length;

  return (
    // The Carousel library is unmaintained
    //
    // See https://github.com/leandrowd/react-responsive-carousel/blob/master/README.md
    //
    // Consider migrating to another Carousel solution that applies the accessibility
    // standard[1]: wai-aria. radix-ui did not yet provide a completed carousel component
    // in their primitice[2]. Though, we could consider using their solution currently
    // used on their website to start with[3].
    //
    // See:
    // - [1] https://www.w3.org/TR/wai-aria-1.2/
    // - [2] https://github.com/radix-ui/primitives/issues/388
    // - [3] https://github.com/radix-ui/website/blob/main/components/marketing/Carousel.tsx
    <Carousel
      showArrows={false}
      showIndicators={numberOfPictures > 1}
      infiniteLoop={true}
      dynamicHeight={false}
      showStatus={false}
      swipeable={true}
      // https://github.com/leandrowd/react-responsive-carousel/issues/717
      // Enable vertical scroll on mobile
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
      //
      showThumbs={false}
      emulateTouch={true}
      onChange={onChange}
      selectedItem={selectedItem}
    >
      {numberOfPictures > 0
        ? profile.pictures.original.map((url, i) => (
            <ProfileImage key={i} src={url} alt={profile.full_name} />
          ))
        : [
            <ProfileImage
              key={"placeholder"}
              src={"/images/profile_picture_round_rect.svg"}
              alt={profile.full_name}
            />,
          ]}
    </Carousel>
  );
}

export default ProfileCarousel;
