import { css, keyframes } from "styled-components";

const keyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`;

/** @deprecated Migrate to Skeleton */
const mediaContentLoadingAnimation = css`
  background: linear-gradient(
    to right,
    var(--grey-02) 4%,
    var(--grey-low) 25%,
    var(--grey-02) 36%
  );
  background-size: 80vw 100%;
  animation: ${keyframesShimmer} 2s infinite linear;
`;

export { mediaContentLoadingAnimation };
