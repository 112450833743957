import {
  FavoriteStatus,
  useFavorites,
} from "@components/Favorites/FavoritesContext";
import { HeartFilledIcon, HeartIcon } from "@radix-ui/react-icons";
import { IconButton, Tooltip } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import React from "react";
import FavoriteAuthPrompt, {
  FavoriteAuthPromptProps,
} from "./FavoriteAuthPrompt";

type FavoriteButtonProps = FavoriteAuthPromptProps;

const FavoriteButton = (props: FavoriteButtonProps) => {
  const { favorites, addFavorite, removeFavorite, status } = useFavorites();
  const isFavorite = favorites.includes(props.therapist_user_id);
  const t = useTranslations("Favorites");
  const toggleFavorite = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isFavorite) {
      await removeFavorite({
        userId: props.therapist_user_id,
        sourcePage: props.currentPage,
      });
    } else {
      await addFavorite(
        {
          userId: props.therapist_user_id,
          sourcePage: props.currentPage,
        },
        false,
      );
    }
  };

  if (status === FavoriteStatus.NotLoggedIn) {
    return <FavoriteAuthPrompt {...props} />;
  }

  const tooltipText = getTooltipKey(isFavorite, status);
  const disabled =
    status === FavoriteStatus.Loading ||
    status === FavoriteStatus.NotAvailable ||
    status === FavoriteStatus.WrongUserType;
  //   return a button that toggles the favorite status with a heart_filled svg if its a favorite or heard_unfilled svg if not
  return (
    <Tooltip content={tooltipText ? t(tooltipText) : ""}>
      <IconButton
        radius="full"
        variant="ghost"
        onClick={toggleFavorite}
        size={"2"}
        disabled={disabled}
      >
        {isFavorite ? (
          <HeartFilledIcon width={"20"} height={"20"} />
        ) : (
          <HeartIcon width={"20"} height={"20"} />
        )}
      </IconButton>
    </Tooltip>
  );
};

function getTooltipKey(isFavorite: boolean, status: FavoriteStatus) {
  switch (status) {
    case FavoriteStatus.Loading:
      return null;
    case FavoriteStatus.NotAvailable:
      return "tooltip_problem_loading_favorites";
    case FavoriteStatus.WrongUserType:
      return "tooltip_favorites_you_must_be_registered";
    default:
      return isFavorite
        ? "tooltip_remove_from_favorites"
        : "tooltip_save_to_favorites";
  }
}

export default FavoriteButton;
