import { useTranslations } from "next-intl";
import ProfileRichBody from "./ProfileRichBody";
import { Subtitle } from "./ProfileTypography";

type ProfileMyApproachProps = {
  myApproach: string;
  is_premium: boolean;
};

function ProfileMyApproach({ myApproach, is_premium }: ProfileMyApproachProps) {
  const t = useTranslations("Profile");
  if (!myApproach) {
    return null;
  }
  return (
    <section>
      <Subtitle>{t("my_approach")}</Subtitle>
      <ProfileRichBody render_html={is_premium} text={myApproach} />
    </section>
  );
}

export default ProfileMyApproach;
