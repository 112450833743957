import styled from "styled-components";
import { QUERIES } from "@/design-system/breakpoints";
import { FullWidthPrimaryButton } from "@/design-system/components/PrimaryButton";
import ProfileResponseTimeMessage from "@components/Profile/ProfileResponseTimeMessage";
import React from "react";
import { useTherapistInquiry } from "@/contexts/TherapistInquiryContext";
import { Profile } from "@/api/entities/profile";
import { useTranslations } from "next-intl";

function ProfileStickyBookingInfo({ profile }: { profile: Profile }) {
  const { handleSendMessage } = useTherapistInquiry();
  const t = useTranslations("Profile");
  const responseTime = profile.showResponseTime
    ? profile.response_time
    : undefined;
  return (
    <StickyBookingInfo>
      <FullWidthPrimaryButton
        onClick={() => {
          handleSendMessage("contact_button");
        }}
      >
        {t("send_message", { name: profile.firstname })}
      </FullWidthPrimaryButton>
      {!!responseTime && (
        <MobileProfileResponseTimeMessageWrapper>
          <ProfileResponseTimeMessage responseTime={responseTime} />
        </MobileProfileResponseTimeMessageWrapper>
      )}
    </StickyBookingInfo>
  );
}

export { ProfileStickyBookingInfo };

const StickyBookingInfo = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--white);
  height: auto;
  padding: var(--spacer-size-2);
  @media ${QUERIES.mobile} {
    display: flex;
  }
  display: none;
  flex-direction: column;
  align-items: center;
  gap: var(--spacer-size-1);
  z-index: 30;
  /* Add box-shadow to the top */
  box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.1);
`;

const MobileProfileResponseTimeMessageWrapper = styled.div`
  padding-top: var(--spacer-size-1);
  padding-bottom: var(--spacer-size-1);
`;
