import { QUERIES } from "@/design-system/breakpoints";
import * as Popover from "@radix-ui/react-popover";
import { useTranslations } from "next-intl";
import styled from "styled-components";
import { Subtitle } from "./ProfileTypography";
import marker from "/public/marker.svg";

// we may want to revisit these dimensions for responsiveness and loading times
const mapWidth = 1440;
const mapHeight = 500;

interface MapProps {
  latitude: number;
  longitude: number;
}

type ProfileWhereIPracticeProps = {
  latitude: number;
  longitude: number;
  addressLines: string[];
};

function ProfileWhereIPractice({
  latitude,
  longitude,
  addressLines,
}: ProfileWhereIPracticeProps) {
  const t = useTranslations("Profile");

  return (
    <section>
      <Subtitle>{t("where_i_practice")}</Subtitle>
      <MapHolder latitude={latitude} longitude={longitude}>
        <Popover.Root>
          <PopoverTrigger asChild>
            <Marker
              src={marker.src}
              alt="therapist illustration"
              style={{ objectFit: "contain" }}
            />
          </PopoverTrigger>
          <PopoverContent side="right">
            {addressLines.map((line, index) => (
              <DirectionsText key={index}>{line}</DirectionsText>
            ))}
            <p>
              <DirectionsLink
                href={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("get_directions")}
              </DirectionsLink>
            </p>
            <PopoverArrow />
          </PopoverContent>
        </Popover.Root>
      </MapHolder>
    </section>
  );
}

export default ProfileWhereIPractice;

const MapHolder = styled.div<MapProps>`
  position: relative;
  height: 250px;
  width: 100%;
  background-position: 50% calc(50% + 37px);
  background-repeat: no-repeat;
  background-image: ${(props) =>
    `url(https://maps.googleapis.com/maps/api/staticmap?&size=${mapWidth}x${mapHeight}&scale=2&style=element:geometry%7Ccolor:0xf5f5f5&style=element:geometry.fill%7Ccolor:0xeffbf9&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x636d6b&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:poi%7Celement:geometry%7Ccolor:0xdde9e7&style=feature:road%7Ccolor:0xffffff&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road%7Celement:geometry.fill%7Ccolor:0xffffff&style=feature:road%7Celement:geometry.stroke%7Ccolor:0xffffff&style=feature:water%7Celement:geometry%7Ccolor:0xdde9e7&center=${props.latitude},${props.longitude}&zoom=13&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY})`};

  @media ${QUERIES.mobile} {
    aspect-ratio: 1 / 1;
    height: auto;
  }
`;

const PopoverContent = styled(Popover.Content)`
  background-color: var(--white);
  border-radius: 4px;
  border-color: rgba(255, 255, 255, 0);
  padding: var(--spacer-size-3);
  margin-left: var(--spacer-size-1);
  width: auto;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  :focus {
    outline: none;
  }
`;

const PopoverArrow = styled(Popover.Arrow)`
  fill: var(--white);
  z-index: -1;
`;

const PopoverTrigger = styled(Popover.Trigger)`
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
`;

const Marker = styled.img`
  width: 53px;
  height: 74px;
  margin-top: -37px;
  margin-left: -26.5px;
`;

const DirectionsText = styled.p`
  font-size: var(--font-size-16);
  font-weight: 400;
  color: var(--black);
`;

const DirectionsLink = styled.a`
  font-size: var(--font-size-16);
  font-weight: 400;
  color: var(--green);
  text-decoration: none;
  cursor: pointer;
`;
