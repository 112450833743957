import { Subtitle } from "@components/Profile/ProfileTypography";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useTranslations } from "next-intl";
import styled from "styled-components";
import AvailabilityDayWithSlots from "./AvailabilityDayWithSlots";
import { convertUTCTimeslotsToAvailabilityByDay } from "@/utils/TimeUtils";
import { useMemo } from "react";

interface AvailabilityScrollAreaProps {
  upcomingAvailabilitySlots: string[];
}

export default function AvailabilityScrollArea({
  upcomingAvailabilitySlots,
}: AvailabilityScrollAreaProps) {
  const t = useTranslations("Profile");
  const localSlots = useMemo(
    () => convertUTCTimeslotsToAvailabilityByDay(upcomingAvailabilitySlots),
    [upcomingAvailabilitySlots],
  );
  return (
    <section>
      <Subtitle>{t("availability")}</Subtitle>
      <ScrollAreaRoot>
        <ScrollAreaViewport>
          {localSlots.map(([day, slots]) => (
            <AvailabilityDayWithSlots key={day} isoDate={day} slots={slots} />
          ))}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar>
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ScrollAreaRoot>
    </section>
  );
}

const ScrollAreaRoot = styled(ScrollArea.Root)`
  border-radius: 24px;
  background-color: var(--grey-light);
`;

const ScrollAreaViewport = styled.div`
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: var(--spacer-size-4);
  padding-top: var(--spacer-size-2);
  padding-left: var(--spacer-size-4);
  padding-right: var(--spacer-size-4);
`;

const ScrollAreaScrollbar = styled.div`
  display: flex;
  padding-bottom: var(--spacer-size-2);

  // set the height and flex-direction for horizontal scrollbar
  flex-direction: column;
  height: var(--scrollbar-size);
`;

const ScrollAreaThumb = styled.div`
  flex: 1;
  border-radius: var(--scrollbar-size);
`;
