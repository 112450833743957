import { profile as api } from "@/api/entities";
import { QUERIES } from "@/design-system/breakpoints";
import { Item, RadioGroup, Root } from "@radix-ui/react-radio-group";
import { AlertDialog, Flex, Tooltip } from "@radix-ui/themes";
import moment from "moment";
import { useTranslations } from "next-intl";
import React, { ChangeEvent, FormEvent, useRef } from "react";
import styled from "styled-components";
import { TimeSlot, UpcomingAvailabilityByDay } from "@/utils/TimeUtils";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { TextV2 } from "@/design-system/components/text/TextV2";

export type ProfileContactMessage = {
  profile: api.Profile;
  upcomingAvailabilitySlots: UpcomingAvailabilityByDay;
  isBusy: boolean;
  message: string;
  setMessage: (message: string) => void;
  selectedDatetime: string | undefined;
  setSelectedDatetime: (datetime: string) => void;
  showEAPWarning: boolean;
  onSend: (
    message: string,
    appointmentUTCTimestamp: number | undefined,
  ) => void;
};

export function ProfileContactMessage({
  profile,
  upcomingAvailabilitySlots,
  isBusy,
  message,
  setMessage,
  selectedDatetime,
  setSelectedDatetime,
  showEAPWarning,
  onSend,
}: ProfileContactMessage) {
  const t = useTranslations("Profile");
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  const triggerSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSend(
      message,
      selectedDatetime ? moment(selectedDatetime).utc().valueOf() : undefined,
    );
  };

  const sendingDisabled = message.trim() === "";
  return (
    <Flex direction={"column"} overflowY={"auto"} height={"100%"} asChild>
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <ScrollableFormContent>
          <MessageArea
            rows={7}
            value={message}
            onChange={handleInputChange}
            placeholder={t("contact.message_placeholder")}
          />
          {upcomingAvailabilitySlots.length > 0 && (
            <AppointmentPickerHolder>
              {(selectedDatetime && (
                <AppointmentPickerTitle
                  dangerouslySetInnerHTML={{
                    __html: t("contact.appointment_slot_selected", {
                      date: `<b>${moment(selectedDatetime).format("L")}</b>`,
                      time: `<b>${moment(selectedDatetime).format("LT")}</b>`,
                    }),
                  }}
                ></AppointmentPickerTitle>
              )) || (
                <AppointmentPickerTitle>
                  {t("contact.select_appointment_slot")}
                </AppointmentPickerTitle>
              )}
              <ProfileContentAppointements
                availabilityEntries={upcomingAvailabilitySlots}
                selectedDatetime={selectedDatetime}
                setSelectedDatetime={setSelectedDatetime}
              />
            </AppointmentPickerHolder>
          )}
        </ScrollableFormContent>
        <Flex
          direction={"column"}
          position={"fixed"}
          bottom={"0"}
          align={"center"}
          gap={"3"}
          py={"3"}
          px={"4"}
          width={"100%"}
          style={{
            background: "linear-gradient(to top, white 50%, transparent 100%",
          }}
        >
          <Tooltip
            content={t("contact.send_button_disabled_tooltip", {
              name: profile.firstname,
            })}
          >
            {/* Wrapper required so pointer events work on the tooltip */}
            <Flex align={"center"} justify={"center"} width={"100%"}>
              {showEAPWarning ? (
                <SendMessageWithEapWarning
                  profile={profile}
                  isBusy={isBusy}
                  disabled={sendingDisabled}
                  triggerSubmit={triggerSubmit}
                />
              ) : (
                <ButtonV2
                  loading={isBusy}
                  variant={"solid"}
                  disabled={sendingDisabled}
                  state={sendingDisabled ? "disabled" : "normal"}
                  size={"4"}
                  type={"submit"}
                  style={{
                    background: sendingDisabled ? "var(--gray-5)" : undefined,
                  }}
                >
                  {t("send_message", {
                    name: profile.firstname,
                  })}
                </ButtonV2>
              )}
            </Flex>
          </Tooltip>
        </Flex>
      </form>
    </Flex>
  );
}

function SendMessageWithEapWarning({
  profile,
  isBusy,
  disabled,
  triggerSubmit,
}: {
  profile: api.Profile;
  isBusy: boolean;
  disabled: boolean;
  triggerSubmit: () => void;
}) {
  const t = useTranslations("Profile");
  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger>
        <ButtonV2
          loading={isBusy}
          variant={"solid"}
          disabled={disabled}
          state={disabled ? "disabled" : "normal"}
          size={"4"}
          type={"button"}
          style={{ background: disabled ? "var(--gray-5)" : undefined }}
        >
          {t("send_message", {
            name: profile.firstname,
          })}
        </ButtonV2>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>
          {t("contact.eap_warning_alert.title")}
        </AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction={"column"} gap={"3"}>
            <TextV2 textStyle={"Body S"}>
              {t("contact.eap_warning_alert.message_1", {
                name: profile.firstname,
              })}
            </TextV2>
            <TextV2 textStyle={"Body S"}>
              {t("contact.eap_warning_alert.message_2", {
                name: profile.firstname,
              })}
            </TextV2>
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <ButtonV2 variant="soft" color="gray" type={"button"}>
              {t("contact.eap_warning_alert.cancel")}
            </ButtonV2>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <ButtonV2 variant="solid" color="mint" onClick={triggerSubmit}>
              {t("contact.eap_warning_alert.send")}
            </ButtonV2>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}

type ProfileContentAppointementsProps = {
  availabilityEntries: UpcomingAvailabilityByDay;
  selectedDatetime?: string;
  setSelectedDatetime: (datetime: string) => void;
};

function ProfileContentAppointements(props: ProfileContentAppointementsProps) {
  // The availability radio group is not in a form control because radix forms cannot
  // validate the radix radio group at the time this was created it returns a "setCustomValidity
  // is not a function" error, so we manage this outside the form
  return (
    <AvailabilityRadioGroup
      value={props.selectedDatetime}
      onValueChange={props.setSelectedDatetime}
    >
      <div>
        {props.availabilityEntries.map(([isoDate, slots]) => {
          const date = moment(isoDate);
          const dayOfWeek = date.format("ddd"); // Format for the day of the week as a word
          const formattedDate = date.format("DD.MM"); // Format for "DD.MM"
          return (
            <div key={isoDate}>
              <AppointmentPickerDateWrapper>
                <ApptPickerWeekday>{dayOfWeek}</ApptPickerWeekday>
                <span>
                  <ApptPickerDate>{formattedDate}</ApptPickerDate>
                </span>
                <AppointmentPickerDateRule />
              </AppointmentPickerDateWrapper>
              <AppointmentPickerTimeWrapper>
                {slots.map((slot: TimeSlot) => {
                  return (
                    <AppointmentItem
                      key={slot.startUTCFormatted}
                      value={slot.startUTCFormatted}
                    >
                      <AppointmentIndicator asChild>
                        <span>{slot.startLocalFormatted}</span>
                      </AppointmentIndicator>
                    </AppointmentItem>
                  );
                })}
              </AppointmentPickerTimeWrapper>
            </div>
          );
        })}
      </div>
    </AvailabilityRadioGroup>
  );
}
const MessageArea = styled.textarea`
  display: block;
  padding: 12px;
  resize: none;
  min-height: 200px;

  border: 2px solid var(--grey-light);
  outline: none;

  @media ${QUERIES.mobile} {
    height: calc(100% - 80px);
  }
`;

const ScrollableFormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: var(--spacer-size-4);
  /* optical alignment: account for the sapce taken by the form invisible scrollbar */
  padding-bottom: var(--spacer-size-7);
  height: 100%;
`;

const AppointmentPickerHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppointmentPickerTitle = styled.div`
  font-size: var(--font-size-16);
  font-weight: 400;
  color: var(grey-dark-1);
  margin-top: var(--spacer-size-4);
  margin-bottom: var(--spacer-size-2);
  margin-left: var(--spacer-size-2);
  margin-right: var(--spacer-size-2);
`;

const AvailabilityRadioGroup = styled(Root)`
  display: grid;
  margin-bottom: var(--spacer-size-3);
  margin-left: var(--spacer-size-2);
  margin-right: var(--spacer-size-2);
`;

const ApptPickerWeekday = styled.span`
  font-size: var(--font-size-16);
  font-weight: 500;
  color: var(--black);
`;
const ApptPickerDate = styled.span`
  font-size: var(--font-size-16);
  font-weight: 500;
  color: var(--grey-dark-1);
`;

const AppointmentPickerDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacer-size-1);
  align-items: center;
  margin-top: var(--spacer-size-2);
  margin-bottom: var(--spacer-size-2);
`;

const AppointmentPickerDateRule = styled.hr`
  flex-grow: 1;
  border-top: 1px solid var(--grey-medium);
  border-bottom: none;
  border-left: none;
  border-right: none;
`;

const AppointmentPickerTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacer-size-2);
  align-items: center;
  overflow-x: auto;
  margin-bottom: var(--spacer-size-4);
`;

const AppointmentItem = styled(Item)`
  width: auto;
  height: 36px;
  border-radius: 24px;
  border: 0;
  cursor: pointer;
  padding: 0 12px;

  &:hover {
    background-color: var(--grey-medium);
  }

  &[data-state="checked"] {
    background-color: var(--green);
    color: var(--white);
  }

  &[data-state="unchecked"] {
    background-color: var(--grey-light);
  }
`;

const AppointmentIndicator = styled(RadioGroup)`
  height: 100%;
  white-space: nowrap;
  font-size: var(--font-size-16);
  font-weight: 500;

  &[data-state="checked"] {
    color: var(--white);
  }

  &[data-state="unchecked"] {
    color: var(--grey-dark-1);
  }
`;
