import {
  LeftArrowButton,
  RightArrowButton,
} from "@/design-system/components/ArrowButtons";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import ProfileCarousel from "./ProfileCarousel";
import { profile as api } from "@/api/entities";

type ProfileCarouselProps = { profile: api.Profile };

function ProfileCarouselWithArrows({ profile }: ProfileCarouselProps) {
  const [selectedSlide, setSelectedSlide] = useState<number>(0);
  const numberOfPictures = profile.pictures.original.length;

  return (
    <AvailabilityCarouselWrapper>
      <CarouselWrapper>
        <ProfileCarousel
          profile={profile}
          selectedItem={selectedSlide}
          onChange={(index) => setSelectedSlide(index)}
        />
      </CarouselWrapper>
      {numberOfPictures > 1 && selectedSlide < numberOfPictures - 1 && (
        <CarouselRightArrowButton>
          <RightArrowButton
            onClick={() => setSelectedSlide(selectedSlide + 1)}
          />
        </CarouselRightArrowButton>
      )}
      {numberOfPictures > 1 && selectedSlide > 0 && (
        <CarouselLeftArrowButton>
          <LeftArrowButton
            onClick={() => setSelectedSlide(selectedSlide - 1)}
          />
        </CarouselLeftArrowButton>
      )}
    </AvailabilityCarouselWrapper>
  );
}

export default ProfileCarouselWithArrows;

const AvailabilityCarouselWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-2);
`;

const CarouselWrapper = styled.div`
  padding: 0 var(--spacer-size-4);
  min-width: 0;
`;

const CarouselLeftArrowButton = styled.div`
  left: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  cursor: pointer;
  z-index: 2;
  padding: 0;
  margin: 0;
`;

const CarouselRightArrowButton = styled.div`
  right: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  cursor: pointer;
  z-index: 2;
`;
