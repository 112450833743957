"use client";
import { blog as api } from "@/api";
import Image from "next/legacy/image";
import { Box, Flex, Link } from "@radix-ui/themes";
import { ReadBlogArticleCTA } from "@components/Editorial/ReadBlogArticleCTA";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import styles from "./Editorial.module.scss";
import React from "react";

type PostCardProperties = { post: api.BlogPost };

function BlogPost({ post }: PostCardProperties) {
  return (
    <Link
      className={styles.cardAnchor}
      onClick={() => {
        window.open(post.link, "_blank");
      }}
      style={{ flexGrow: 1 }}
      asChild
    >
      <Flex
        direction={"column"}
        gap={"5"}
        p={"5"}
        className={styles.postContainer}
      >
        <Flex direction={"column"} gap={"5"} flexGrow={"1"}>
          <Box className={styles.postImageWrapper}>
            <Image
              layout="fill"
              src={post.image}
              objectFit="cover" // Ensures the image covers the area, maintaining aspect ratio
              alt="blog image"
            />
          </Box>
          <HeadingV2
            textStyle={"Headline S"}
            dangerouslySetInnerHTML={{ __html: post.title }}
            as={"h3"}
          />
          <TextV2
            textStyle={"Body M"}
            dangerouslySetInnerHTML={{ __html: post.excerpt }}
          />
        </Flex>
        <ReadBlogArticleCTA href={post.link} />
      </Flex>
    </Link>
  );
}

export { BlogPost };
