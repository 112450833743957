import { useTranslations } from "next-intl";
import { useMemo, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import { Subtitle } from "../ProfileTypography";
import AvailabilityDayWithSlots from "./AvailabilityDayWithSlots";
import { convertUTCTimeslotsToAvailabilityByDay } from "@/utils/TimeUtils";

interface AvailabilityCarouselProps {
  upcomingAvailabilitySlots: string[];
  itemsPerPage: number; // this should be odd, so we have a center slide
}

export default function AvailabilityCarousel({
  upcomingAvailabilitySlots,
  itemsPerPage,
}: AvailabilityCarouselProps) {
  const [selectedSlide, setSelectedSlide] = useState<number>(1);
  const t = useTranslations("Profile");
  const upcomingAvailability = useMemo(
    () => convertUTCTimeslotsToAvailabilityByDay(upcomingAvailabilitySlots),
    [upcomingAvailabilitySlots],
  );

  if (!upcomingAvailability || upcomingAvailability.length === 0) {
    return null;
  }

  const idealFirstSlide = Math.floor(itemsPerPage / 2);
  const idealLastSlide =
    upcomingAvailability.length - Math.floor(itemsPerPage / 2) - 1;

  return (
    <AvailabilityCarouselWrapper>
      <CarouselTitle>{t("availability")}</CarouselTitle>
      <CarouselWrapper>
        <StyledCarousel
          centerMode
          centerSlidePercentage={100 / itemsPerPage}
          selectedItem={Math.min(
            Math.max(selectedSlide || idealFirstSlide, idealFirstSlide),
            idealLastSlide,
          )}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          emulateTouch={true}
          onChange={(index) => setSelectedSlide(index)}
          showArrows={false}
        >
          {upcomingAvailability.map(([day, slots]) => (
            <AvailabilityDayWithSlots key={day} isoDate={day} slots={slots} />
          ))}
        </StyledCarousel>
      </CarouselWrapper>
      <RightArrowButton
        onClick={() =>
          setSelectedSlide(
            Math.min(selectedSlide + itemsPerPage, idealLastSlide),
          )
        }
        disabled={!selectedSlide || selectedSlide >= idealLastSlide}
      >
        <RightArrow />
      </RightArrowButton>
      <LeftArrowButton
        onClick={() =>
          setSelectedSlide(
            Math.max(selectedSlide - itemsPerPage, idealFirstSlide),
          )
        }
        disabled={!selectedSlide || selectedSlide <= idealFirstSlide}
      >
        <LeftArrow />
      </LeftArrowButton>
    </AvailabilityCarouselWrapper>
  );
}
const AvailabilityCarouselWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-2);
`;

const CarouselTitle = styled(Subtitle)`
  padding: 0 var(--spacer-size-4);
  width: 100%;
  text-align: center;
`;

const CarouselWrapper = styled.div`
  padding: 0 var(--spacer-size-4);
  min-width: 0;
`;
const StyledCarousel = styled(Carousel)`
  min-width: 0;
  .slider-wrapper {
    /* For the fading edge effect */
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30px;
      pointer-events: none;
      z-index: 1; /* Higher than the content's z-index */
    }

    &::before {
      left: 0;
      background: linear-gradient(
        to right,
        var(--grey-light),
        var(--grey-light),
        var(--grey-light-semi)
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        to left,
        var(--grey-light),
        var(--grey-light),
        var(--grey-light-semi)
      );
    }
  }
`;

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: var(--primary-color);
  background-color: var(--primary-background-color);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: var(--green-over);
  }

  &:disabled {
    background-color: var(--grey-light);
    color: var(--grey-dark-1);
    cursor: not-allowed;
  }
`;

const LeftArrowButton = styled(ArrowButton)`
  left: 10px;
`;

const RightArrowButton = styled(ArrowButton)`
  right: 10px;
`;

const RightArrow = styled.span`
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

const LeftArrow = styled.span`
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
`;
