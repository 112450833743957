import { QUERIES } from "@/design-system/breakpoints";
import React from "react";
import styled from "styled-components";
import { ClientTextUtils } from "@/utils/ClientTextUtils";
import { Avatar } from "@radix-ui/themes";
import { PersonIcon } from "@radix-ui/react-icons";

type ProfileContactHeaderProps = {
  picture_url: string;
  full_name: string;
  jobtitle: string;
};

export function ProfileContactHeader({
  picture_url,
  full_name,
  jobtitle,
}: ProfileContactHeaderProps) {
  return (
    <FormHeaderContainer>
      <ContactHeader>
        <ProfileImage src={picture_url} />
        <div>
          <ContactHeaderName>{full_name}</ContactHeaderName>
          <ContactHeaderJob>{jobtitle}</ContactHeaderJob>
        </div>
      </ContactHeader>
    </FormHeaderContainer>
  );
}

function ProfileImage({ src }: { src: string }) {
  return (
    <Avatar
      radius={"medium"}
      size={"7"}
      color={"teal"}
      fallback={<PersonIcon width={80} height={80} />}
      src={src}
      alt="Profile Picture"
    />
  );
}

export const FormHeaderContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 12px;
  background-color: var(--green-low);

  @media ${QUERIES.mobile} {
    margin: revert;
  }
`;

export const ContactHeader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: var(--spacer-size-6);
`;

export const ContactHeaderName = styled.div`
  font-size: var(--font-size-22);
  font-weight: 600;
  color: var(--black);
  text-align: left;
  ${ClientTextUtils.maxTextLines(2)}
`;

export const ContactHeaderJob = styled.div`
  font-size: var(--font-size-18);
  color: var(--green-low-0);
  text-align: left;
  ${ClientTextUtils.maxTextLines(2)}
`;
