import styled from "styled-components";

const Subtitle = styled.h2`
  font-size: var(--font-size-24);
  font-weight: 500;
  margin-bottom: var(--spacer-size-3);
  color: ${(props) => props.color || "var(--black)"};
`;

const Body = styled.div`
  font-size: var(--font-size-18);
  font-weight: 400;
  color: ${(props) => props.color || "var(--black)"};
  // we need to use pre-line to preserve line breaks in peoples profile content
  white-space: pre-line;

  //  this is  same as darkGreenLink. Should share this actually
  a {
    color: var(--colorV2-darkest-green);
    text-decoration: underline;

    &:hover {
      color: var(--colorV2-dark-green);
    }
    &:focus {
      color: var(--colorV2-dark-green);
    }
  }
`;

export { Subtitle, Body };
