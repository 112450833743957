"use client";
import styled from "styled-components";
import { useTherapistInquiry } from "@/contexts/TherapistInquiryContext";
import { ISODate, TimeSlot } from "@/utils/TimeUtils";
import moment from "moment";
import { useEffect, useState } from "react";

type AvailabilityDayWithSlotsProps = {
  isoDate: ISODate;
  slots: TimeSlot[];
};
export default function AvailabilityDayWithSlots({
  isoDate,
  slots,
}: AvailabilityDayWithSlotsProps) {
  const { handleAppointmentRequest } = useTherapistInquiry();

  // Initialize state with default (UTC or placeholder) values
  const placeholder = "&nbsp;";
  const [dayOfWeek, setDayOfWeek] = useState(placeholder);
  const [formattedDate, setFormattedDate] = useState(placeholder);

  useEffect(() => {
    // Update state with client-specific time after component mounts
    const date = moment(isoDate);
    setDayOfWeek(date.format("ddd")); // e.g., "Mon"
    setFormattedDate(date.format("DD.MM")); // e.g., "01.01"
  }, [isoDate]);

  return (
    <div key={isoDate}>
      <AvailabilityDateWrapper>
        <AvailabilityWeekday dangerouslySetInnerHTML={{ __html: dayOfWeek }} />
        <span>
          <AvailabilityDate
            dangerouslySetInnerHTML={{ __html: formattedDate }}
          />
        </span>
        <AvailabilityDateRule />
      </AvailabilityDateWrapper>
      <AvailabilityTimeWrapper>
        {slots.map((slot: TimeSlot) => {
          return (
            <AvailabilityItem
              key={slot.startUTCFormatted}
              onClick={() =>
                handleAppointmentRequest(
                  slot.startUTCFormatted,
                  "appointment_picker",
                )
              }
            >
              <span>
                {dayOfWeek != placeholder ? slot.startLocalFormatted : ""}
              </span>
            </AvailabilityItem>
          );
        })}
      </AvailabilityTimeWrapper>
    </div>
  );
}

const AvailabilityWeekday = styled.span`
  font-size: var(--font-size-16);
  font-weight: 500;
  color: var(--black);
`;
const AvailabilityDate = styled.span`
  font-size: var(--font-size-16);
  font-weight: 500;
  color: var(--grey-dark-1);
`;

const AvailabilityDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-1);
  align-items: center;
  margin-top: var(--spacer-size-2);
  margin-bottom: var(--spacer-size-2);
`;

const AvailabilityDateRule = styled.hr`
  flex-grow: 1;
  border-top: 1px solid var(--grey-medium);
  border-bottom: none;
  border-left: none;
  border-right: none;
`;

const AvailabilityTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--spacer-size-2);
  align-items: center;
  overflow-x: auto;
  margin-bottom: var(--spacer-size-4);
`;

const AvailabilityItem = styled.button`
  width: 60px;
  height: 36px;
  border-radius: 24px;
  border: 0;
  cursor: pointer;
  background-color: var(--white);
  color: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
`;
