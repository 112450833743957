import { css } from "styled-components";

export class ClientTextUtils {
  static maxTextLines(n: number) {
    /*
                    Limit text length to n lines using CSS
                    https://stackoverflow.com/a/13924997
                    */
    return css`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: ${n};
      line-clamp: ${n};
      -webkit-box-orient: vertical;
    `;
  }
}
