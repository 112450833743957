"use client";
import { profile as api } from "@/api/entities";
import { QUERIES } from "@/design-system/breakpoints";
import GreenTag from "@/design-system/components/GreenTag";
import RedTag from "@/design-system/components/RedTag";
import { useTranslations } from "next-intl";
import styled from "styled-components";
import { ProfileVideo } from "./ProfileVideo";
import ProfileCarousel from "./ProfileCarousel";
import FavoriteButton from "../Favorites/FavoriteButton";

type ProfileHeaderProps = { profile: api.Profile };

function ProfileHeader({ profile }: ProfileHeaderProps) {
  const { full_name, jobtitle, is_available } = profile;
  const t = useTranslations("Profile");
  const availabilityTag = (available: boolean) => {
    return available ? (
      <GreenTag>{t("available")}</GreenTag>
    ) : (
      <RedTag>{t("unavailable")}</RedTag>
    );
  };
  const landscapeVideo = profile.active_videos.find(
    (video) => video.orientation === "landscape",
  );

  return (
    <section>
      <HeaderSpacer />
      <HeaderIfMobile>
        <MobileAvailabilityTagWrapper>
          {availabilityTag(is_available)}
        </MobileAvailabilityTagWrapper>
        {landscapeVideo ? (
          <ProfileVideo
            video={landscapeVideo}
            therapistUserId={profile.user.id.toString()}
          />
        ) : (
          <ProfileCarousel profile={profile} />
        )}
      </HeaderIfMobile>
      <FullnameAndFavorite>
        <FullName>{full_name}</FullName>
        <FavoriteButton
          therapist_user_id={profile.user.id}
          picture_url={profile.profile_picture_url}
          jobtitle={profile.jobtitle}
          full_name={profile.full_name}
          therapist_first_name={profile.firstname}
          currentPage="therapist_profile_page"
        />
      </FullnameAndFavorite>
      <JobTitleAndAvailability>
        <JobTitle>{jobtitle}</JobTitle>
        <WebAvailabilityTagWrapper>
          {availabilityTag(is_available)}
        </WebAvailabilityTagWrapper>
      </JobTitleAndAvailability>
    </section>
  );
}

export default ProfileHeader;

const HeaderSpacer = styled.div`
  height: var(--spacer-size-7);
  @media ${QUERIES.mobile} {
    height: var(--spacer-size-5);
  }
`;
const HeaderIfMobile = styled.div`
  display: none;

  @media ${QUERIES.mobile} {
    display: inline;
    width: 100%;
    height: auto;
  }
`;

const MobileAvailabilityTagWrapper = styled.div`
  position: absolute;
  float: right;
  right: 8px;
  margin-top: -8px;
  background-color: white;
  border-radius: 12px;
  border: 4px solid white;
  z-index: 10;
`;

const FullName = styled.h1`
  font-size: var(--font-size-48);
  font-weight: 400;
  color: var(--black);
`;

const JobTitle = styled.h2`
  font-size: var(--font-size-20);
  font-weight: 400;
  color: var(--grey-dark-1);
`;

const JobTitleAndAvailability = styled.div`
  /* Use display inline for the natural text-like wrapping */
  & > * {
    display: inline;
  }

  /* Additional styling to manage gaps, if needed */
  & > ${JobTitle}:not(:last-child)::after {
    content: " ";
  }
`;

const WebAvailabilityTagWrapper = styled.div`
  margin-left: 4px; /* Instead of flex-gap, use margin for spacing */

  @media ${QUERIES.mobile} {
    display: none;
  }
`;

const FullnameAndFavorite = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
