import { useTranslations } from "next-intl";
import { Subtitle } from "./ProfileTypography";
import { BlogPost } from "@components/Editorial/BlogPost";
import { Flex, Section } from "@radix-ui/themes";
import React from "react";
import { blog as blogApi } from "@/api";

function ProfileBlogPosts({ blogPosts }: { blogPosts: blogApi.BlogPost[] }) {
  const t = useTranslations("Profile");
  return (
    <Section pt={"1"}>
      <Flex direction={"column"} gap={"3"}>
        <Subtitle>{t("published_articles.title")}</Subtitle>
        <Flex
          direction={{ initial: "column", sm: "row" }}
          wrap={"wrap"}
          gap={"3"}
        >
          {blogPosts.map((post, index) => (
            <BlogPost key={index} post={post}></BlogPost>
          ))}
        </Flex>
      </Flex>
    </Section>
  );
}

export default ProfileBlogPosts;
