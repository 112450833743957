import GreenTag from "@/design-system/components/GreenTag";
import { useTranslations } from "next-intl";
import styled from "styled-components";
import ProfileRichBody from "./ProfileRichBody";
import { Body, Subtitle } from "./ProfileTypography";
import { Link } from "@/navigation";

type ProfileAboutAndCategoriesProps = {
  about: string;
  is_premium: boolean;
  categories: { name: string; slug: string }[];
};

function ProfileAboutAndCategories({
  about,
  is_premium,
  categories,
}: ProfileAboutAndCategoriesProps) {
  const t = useTranslations("Profile");
  return (
    <Container>
      <Subtitle>{t("about_me")}</Subtitle>
      <ProfileRichBody render_html={is_premium} text={about} />
      <SpecialtiesWrapper>
        <Body color="var(--green-dark)">{t("specialties")}</Body>
        <SpecialtiesItemsWrapper>
          {categories.map((category) => (
            <Link
              key={category.slug}
              href={{
                pathname: "/find-help-with/[category_slug]",
                params: { category_slug: category.slug },
              }}
            >
              <CategoryTag>{category.name}</CategoryTag>
            </Link>
          ))}
        </SpecialtiesItemsWrapper>
      </SpecialtiesWrapper>
    </Container>
  );
}

export default ProfileAboutAndCategories;

const Container = styled.section``;

const SpecialtiesWrapper = styled.div`
  color: var(--green-dark);
  margin-top: 18px;
`;

const SpecialtiesItemsWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const CategoryTag = styled(GreenTag)`
  &:hover {
    background-color: var(--green-low-over);
  }
`;
