"use client";
import * as analytics from "@/analytics";
import { profile as api } from "@/api/entities";
import { mediaContentLoadingAnimation } from "@/design-system/animations/MediaContentLoadingAnimation";
import { QUERIES } from "@/design-system/breakpoints";
import dynamic from "next/dynamic";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const VideoPlayer = dynamic(() => import("../VideoPlayer/VideoPlayer"), {
  ssr: false,
});

function ProfileVideo({
  video: video,
  therapistUserId,
}: {
  video: api.ProfileVideoVariant;
  therapistUserId: string;
}) {
  const [playing, setPlaying] = React.useState(false);
  const [hasLoggedPlay, setHasLoggedPlay] = React.useState(false);
  const player = React.useRef<ReactPlayer>(null);
  const playTimeCalculator = usePlayTimeCalculator(therapistUserId);

  const handleTogglePlay: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (!playing) {
      playTimeCalculator.onTogglePlay();

      if (!hasLoggedPlay) {
        analytics.directory.profileVideoPlayInitiated({
          therapist_user_id: therapistUserId,
          source_page: "therapist_profile_page",
        });
        setHasLoggedPlay(true); // Ensure this doesn't get called again
      }
    }
    setPlaying(!playing);
  };

  const shouldShowControls = !useMediaQuery({ query: QUERIES.mobile });
  return (
    <VideoWrapper onClick={handleTogglePlay}>
      <VideoPlayer
        video={video.stream_url}
        videoPreviewHigh={video.still_image_url_med}
        videoPreviewLow={video.still_image_blurred_url}
        playing={playing}
        onPlay={() => {
          playTimeCalculator.onPlay();
          return setPlaying(true);
        }}
        onPause={() => {
          playTimeCalculator.onPause();
          setPlaying(false);
        }}
        playerRef={player}
        onEnded={() => {
          setPlaying(false);
          player?.current?.showPreview();
        }}
        onError={() => {
          playTimeCalculator.onError();
          setPlaying(false);
          player?.current?.showPreview();
          analytics.directory.profileVideoError({
            therapist_user_id: therapistUserId,
            source_page: "therapist_profile_page",
          });
        }}
        showControls={shouldShowControls}
      />
    </VideoWrapper>
  );
}

const usePlayTimeCalculator = (therapistUserId: string) => {
  /**
   * Calculate time to play from the time they click play. We also track pause and errors as they can skew the results,
   * so we can filter them out later if we want.
   */

  const startPlayTimestamp = useRef<number | null>(null);
  const errorOccurred = useRef<boolean>(false);
  const pauseOccurred = useRef<boolean>(false);
  const [loadTime, setLoadTime] = useState<number | null>(null);

  const onTogglePlay = () => {
    startPlayTimestamp.current = new Date().getTime();
  };

  const onPlay = () => {
    if (loadTime === null && startPlayTimestamp.current !== null) {
      const currentTime = new Date().getTime();
      setLoadTime(currentTime - startPlayTimestamp.current);
    }
  };

  const onPause = () => {
    pauseOccurred.current = true;
    startPlayTimestamp.current = null;
  };
  const onError = () => {
    errorOccurred.current = true;
    startPlayTimestamp.current = null;
  };

  useEffect(() => {
    if (loadTime !== null) {
      analytics.directory.profileVideoStarted({
        source_page: "therapist_profile_page",
        therapist_user_id: therapistUserId,
        load_time_ms: loadTime,
        error_occurred_before_start: errorOccurred.current,
        pause_occurred_before_start: pauseOccurred.current,
      });
    }
  }, [loadTime, therapistUserId]);

  return {
    onTogglePlay,
    onPlay,
    onPause,
    onError,
  };
};

const VideoWrapper = styled.div`
  width: 100%;
  aspect-ratio: 960/540;
  border-radius: 24px;
  overflow: hidden;

  ${mediaContentLoadingAnimation}// hidden by content when loaded
`;

export { ProfileVideo };
