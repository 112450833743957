"use client";
import * as analytics from "@/analytics";
import { ProfileContactHeader } from "@components/Profile/ProfileContact/ProfileContactHeader";
import { useUserSession } from "@/contexts/UserSession";
import { QUERIES } from "@/design-system/breakpoints";
import { Button } from "@/design-system/components/Button";
import { Cross2Icon, HeartIcon } from "@radix-ui/react-icons";
import { Dialog, Flex, IconButton, Text, Tooltip } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import React, { MouseEventHandler, useEffect } from "react";
import styled from "styled-components";
import { useFavorites } from "./FavoritesContext";
import { SourcePage } from "@/analytics/sourcePage";

export type FavoriteAuthPromptProps = {
  picture_url: string;
  full_name: string;
  jobtitle: string;
  therapist_user_id: string;
  therapist_first_name: string;
  currentPage: SourcePage;
};

function FavoriteAuthPrompt({
  picture_url,
  full_name,
  jobtitle,
  therapist_user_id,
  therapist_first_name,
  currentPage,
}: FavoriteAuthPromptProps) {
  const t = useTranslations("Favorites");
  const [open, setOpen] = React.useState(false);
  const handleChildClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog.Root onOpenChange={setOpen} open={open}>
      <Dialog.Trigger>
        <Tooltip content={t("tooltip_save_to_favorites")}>
          <IconButton
            radius="full"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
            size={"2"}
          >
            <HeartIcon width={"20"} height={"20"} />
          </IconButton>
        </Tooltip>
      </Dialog.Trigger>
      <DialogContent onClick={handleChildClick}>
        <Flex direction={"column"}>
          <HeaderWrapper>
            <ProfileContactHeader
              picture_url={picture_url}
              full_name={full_name}
              jobtitle={jobtitle}
            />
            <Flex justify={"end"}>
              <Dialog.Close>
                <DialogCloseButton aria-label="Close">
                  <DialogCloseIcon width={20} height={20} />
                </DialogCloseButton>
              </Dialog.Close>
            </Flex>
          </HeaderWrapper>
          <AuthContent
            name={therapist_first_name}
            userId={therapist_user_id}
            currentPage={currentPage}
          />
        </Flex>
      </DialogContent>
    </Dialog.Root>
  );
}

type AuthContentProps = {
  userId: string;
  name: string;
  currentPage: SourcePage;
};

function AuthContent({ userId, name, currentPage }: AuthContentProps) {
  const t = useTranslations("Favorites");
  const { openLoginPopup, openSignupPopup } = useUserSession();
  const { setPendingFavorite } = useFavorites();
  const returnTo = new URL(window.location.href);
  returnTo.searchParams.set("contact", "1");

  useEffect(() => {
    analytics.auth.trackAuthPromptViewed({
      therapist_user_id: userId,
      source_page: "therapist_profile_page",
      source_flow: "favorite",
    });
  }, [userId]);

  return (
    <AuthContainer>
      <Text>{t("auth_subtitle", { name })}</Text>
      <AuthActions>
        <SignupButton
          secondary
          onClick={(e) => {
            e.preventDefault();
            // do not await, the event will be sent when the user cones back from auth0
            analytics.auth.trackAuthStarted({
              has_contact_intent: false,
              auth_kind: "signup",
            });
            setPendingFavorite({ userId, sourcePage: currentPage });
            openSignupPopup();
          }}
        >
          {t("signup")}
        </SignupButton>
        <LoginButton
          primary
          onClick={(e) => {
            e.preventDefault();
            // do not await, the event will be sent when the user cones back from auth0
            analytics.auth.trackAuthStarted({
              has_contact_intent: false,
              auth_kind: "login",
            });
            setPendingFavorite({ userId, sourcePage: currentPage });
            openLoginPopup();
          }}
        >
          {t("login")}
        </LoginButton>
      </AuthActions>
    </AuthContainer>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  background-color: var(--green-low);
`;

const DialogContent = styled(Dialog.Content)`
  max-width: 450px;
  padding: 0;
`;

const DialogCloseButton = styled(Button).attrs({
  tertiary: true,
  tiny: true,
})`
  height: 40px;
  width: 40px;
`;

const DialogCloseIcon = styled(Cross2Icon)`
  float: right;
  color: var(--black);
`;

const AuthContainer = styled.div`
  flex: 1 0 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacer-size-4);

  @media ${QUERIES.mobile} {
    align-items: center;
  }
`;

const AuthActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-3);
  align-items: start;
  margin-top: 48px;
`;

const LoginButton = styled(Button)`
  min-width: 300px;
  text-align: center;
  text-decoration: none;
`;

const SignupButton = styled(Button)`
  padding: 12px 24px;
  color: var(--black);
  min-width: 300px;
  text-align: center;
  text-decoration: none;
`;

export default FavoriteAuthPrompt;
