import styled from "styled-components";

const GreenTag = styled.div`
  display: inline-block;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: var(--green-low);
  font-size: var(--font-size-14);
  font-weight: 400;
  color: var(--green-dark);
`;

export default GreenTag;
