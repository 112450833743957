import { profile as api } from "@/api/entities";
import { FullWidthPrimaryButton } from "@/design-system/components/PrimaryButton";
import { useMoment } from "@/hooks/useMoment";
import { Link } from "@/navigation";
import { useFormatter, useTranslations } from "next-intl";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AvailabilityCarousel from "./Availability/AvailabilityCarousel";
import ProfileResponseTimeMessage from "./ProfileResponseTimeMessage";
import { useTherapistInquiry } from "@/contexts/TherapistInquiryContext";
import {
  ChatBubbleIcon,
  LaptopIcon,
  PersonIcon,
  QuoteIcon,
} from "@radix-ui/react-icons";
import styles from "./Profile.module.scss";
import { Box, Flex, Separator } from "@radix-ui/themes";
import ActivityIcon from "@/design-system/icons/ActivityIcon";
import CakeIcon from "@/design-system/icons/CakeIcon";
import LocationIcon from "@/design-system/icons/LocationIcon";
import SessionIcon from "@/design-system/icons/SessionIcon";
import PauseButton from "@/design-system/icons/PauseButton";
import { useSessionOfferLabels } from "@/hooks/useSessionOfferLabels";

type ProfileBookingInfoProps = {
  profile: api.Profile;
  upcomingAvailabilitySlots: string[];
  lastActivityAt?: string;
  showBookingControls: boolean;
};

function ProfileBookingInfo(props: ProfileBookingInfoProps) {
  const moment = useMoment();
  const t = useTranslations("Profile");
  const format = useFormatter();
  const { profile, lastActivityAt } = props;
  const [relativeLastActivityAt, setRelativeLastActivityAt] = useState<
    string | undefined
  >(undefined);

  // use an effect to ensure we do not encounter a hydration error due to the relativeTime changing between server and client
  // note that this still may happen in development, due to strict mode
  useEffect(() => {
    setRelativeLastActivityAt(
      lastActivityAt
        ? format.relativeTime(new Date(lastActivityAt), new Date())
        : undefined,
    );
    return () => setRelativeLastActivityAt(undefined);
  }, [format, lastActivityAt]);

  const sessionsCount = profile.sessions_count;
  const visibleSessionCount =
    profile.showSessions && sessionsCount && sessionsCount > 0
      ? sessionsCount < 10
        ? t("sessions_on_IC_less_than_10")
        : t("sessions_on_IC_10_or_more", {
            count: Math.floor(sessionsCount / 10) * 10,
          })
      : undefined;

  const isDateWithinTheLastMonth = (dateStr: string): boolean => {
    const inputDate = moment(dateStr);
    const oneMonthAgo = moment().subtract(1, "months");
    return inputDate.isAfter(oneMonthAgo);
  };

  const joinedAtMessage =
    profile.showJoined && profile.profile_approved_at
      ? isDateWithinTheLastMonth(profile.profile_approved_at)
        ? t("first_month_at_IC")
        : t("time_at_ic", {
            time: moment
              .duration(
                moment().diff(profile.profile_approved_at, "months"),
                "months",
              )
              .humanize(),
          })
      : undefined;

  const { handleSendMessage } = useTherapistInquiry();

  return (
    <Container>
      {profile.showPrice && (
        <BookingWidthWrapper>
          <BookingInfoLine>
            <BookingPrices profile={profile} />
          </BookingInfoLine>
        </BookingWidthWrapper>
      )}
      <BookingDetailsContainer>
        {profile.city && (
          <BookingInfoLine>
            <Box className={styles.bookingInfoIconWrapper}>
              <LocationIcon width={16} height={16} />
            </Box>
            {t("location_from")}
            <Link
              href={{
                pathname: "/find-a-therapist/[city_slug]",
                params: { city_slug: profile.city.slug },
              }}
              className={"nakedGreenLink"}
            >
              {profile.city.name}
            </Link>
          </BookingInfoLine>
        )}
        {profile.is_available && lastActivityAt && (
          <BookingInfoLine>
            <Box className={styles.bookingInfoIconWrapper}>
              <ActivityIcon width={16} height={16} />
            </Box>
            {t("last_active")} {relativeLastActivityAt}
          </BookingInfoLine>
        )}
        {!profile.is_available && (
          <RedBookingInfoLine>
            <Box className={styles.bookingInfoIconWrapperRed}>
              <PauseButton width={16} height={16} />
            </Box>
            {t("not_active")}
          </RedBookingInfoLine>
        )}
        {visibleSessionCount && (
          <BookingInfoLine>
            <Box className={styles.bookingInfoIconWrapper}>
              <SessionIcon width={16} height={16} />
            </Box>
            {visibleSessionCount}
          </BookingInfoLine>
        )}
        {joinedAtMessage && (
          <BookingInfoLine>
            <Box className={styles.bookingInfoIconWrapper}>
              <CakeIcon width={16} height={16} />
            </Box>
            {joinedAtMessage}
          </BookingInfoLine>
        )}
        {profile.supports_in_person_therapy && (
          <BookingInfoLine>
            <Box className={styles.bookingInfoIconWrapper}>
              <PersonIcon width={16} height={16} />
            </Box>
            {t("formats.in_person.supported")}
          </BookingInfoLine>
        )}
        {profile.supports_video_therapy && (
          <BookingInfoLine>
            <Box className={styles.bookingInfoIconWrapper}>
              <LaptopIcon width={16} height={16} />
            </Box>
            {t("formats.online.supported")}
          </BookingInfoLine>
        )}
        {profile.supports_text_therapy && (
          <BookingInfoLine>
            <Box className={styles.bookingInfoIconWrapper}>
              <ChatBubbleIcon width={16} height={16} />
            </Box>
            {t("formats.text.supported")}
          </BookingInfoLine>
        )}

        {profile.status_message && profile.status_message.length > 0 && (
          <>
            <Separator my={"3"} size={"4"} />
            <BookingInfoLine align={"start"}>
              <Box className={styles.bookingInfoIconWrapper}>
                <QuoteIcon width={16} height={16} />
              </Box>
              {profile.status_message}
            </BookingInfoLine>
          </>
        )}
      </BookingDetailsContainer>
      {props.showBookingControls && (
        <BookingWidthWrapper>
          <FullWidthPrimaryButton
            onClick={() => {
              handleSendMessage("contact_button");
            }}
          >
            {t("send_message", { name: profile.firstname })}
          </FullWidthPrimaryButton>
        </BookingWidthWrapper>
      )}
      {props.showBookingControls && !!profile.response_time && (
        <BookingWidthWrapper>
          <ProfileResponseTimeMessage responseTime={profile.response_time} />
        </BookingWidthWrapper>
      )}
      {props.showBookingControls &&
        props.upcomingAvailabilitySlots.length > 0 &&
        profile.calendar_enabled &&
        profile.is_available && (
          <AvailabilityCarousel
            itemsPerPage={3}
            upcomingAvailabilitySlots={props.upcomingAvailabilitySlots}
          />
        )}
    </Container>
  );
}

function BookingInfoLine({
  children,
  align,
}: {
  children: React.ReactNode;
  align?: "center" | "start";
}) {
  return (
    <Flex direction={"row"} align={align || "center"} gap={"2"}>
      {children}
    </Flex>
  );
}

function BookingPrices({ profile }: { profile: api.Profile }) {
  const t = useTranslations("Profile");
  const format = useFormatter();
  const { labelForType } = useSessionOfferLabels();
  return (
    <BookingPriceList>
      <BookingPriceMain>
        {t.rich("price_per_session", {
          highlight: (chunks) => <Price>{chunks}</Price>,
          price: format.number(profile.price_amount, {
            style: "currency",
            currency: profile.price_currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        })}
      </BookingPriceMain>
      {profile.secondary_session_offers.map((offer, i) => (
        <BookingPriceOffer key={i}>
          {t.rich("price_per_offer", {
            highlight: (chunks) => <Price>{chunks}</Price>,
            price: format.number(offer.price, {
              style: "currency",
              currency: offer.currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            offer: offer.title || labelForType(offer.session_type),
            breakWords: (chunks) => {
              const wordBreakOpportunities = chunks?.toString()?.split("/");
              return wordBreakOpportunities &&
                wordBreakOpportunities?.length > 1
                ? wordBreakOpportunities.map((e) => (
                    <>
                      {e}/
                      <wbr />
                    </>
                  ))
                : wordBreakOpportunities;
            },
          })}
        </BookingPriceOffer>
      ))}
    </BookingPriceList>
  );
}

export default ProfileBookingInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-4);
  padding-top: var(--spacer-size-4);
  padding-bottom: var(--spacer-size-4);
  border-radius: 24px;
  background-color: var(--grey-light);
  width: 100%;
  align-items: start;
`;

const BookingWidthWrapper = styled.div`
  padding: 0 var(--spacer-size-4);
  width: 100%;
`;

const BookingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-1);
  padding: 0 var(--spacer-size-4);
  width: 100%;
`;

const BookingPriceList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BookingPriceMain = styled.li`
  font-weight: 500;
  font-size: var(--font-size-26);
`;

const BookingPriceOffer = styled.li`
  margin: 4px 0;
`;

const Price = styled.span`
  color: var(--green);
`;

const RedBookingInfoLine = styled(BookingInfoLine)`
  color: var(--red-dark);
`;
