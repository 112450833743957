import { useTranslations } from "next-intl";
import { SessionOfferType } from "@/api/entities/profile";

function useSessionOfferLabels() {
  const t = useTranslations("EditProfile.PracticeInfo.SessionOffers");

  function getOfferLabel(sessionOfferType: SessionOfferType) {
    switch (sessionOfferType) {
      case SessionOfferType.Individual:
        return t("session_types.individual");
      case SessionOfferType.Couples:
        return t("session_types.couples");
      case SessionOfferType.Family:
        return t("session_types.family");
      case SessionOfferType.Initial:
        return t("session_types.initial");
      case SessionOfferType.Group:
        return t("session_types.group");
      case SessionOfferType.Custom:
        return t("session_types.custom");
      default:
        throw new Error(`Unsupported session offer type: ${sessionOfferType}`);
    }
  }

  return { labelForType: getOfferLabel };
}

export { useSessionOfferLabels };
